import * as THREE from 'three';

const xRayMaterial = new THREE.ShaderMaterial(
  {
    uniforms: {
      p: {type: 'f', value: 2},
      glowColor: {type: 'c', value: new THREE.Color(0xaaaaaa)},
    },
    vertexShader: 'uniform float p;\n' +
      '        varying float intensity;\n' +
      '        void main()\n' +
      '        {\n' +
      '            vec3 vNormal = normalize( normalMatrix * normal );\n' +
      '            intensity = pow(1.0 - abs(dot(vNormal, vec3(0, 0, 1))), p) * 1.6;\n' +
      '            gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );\n' +
      '        }',
    fragmentShader: 'uniform vec3 glowColor;\n' +
      '        varying float intensity;\n' +
      '        void main()\n' +
      '        {\n' +
      '            vec3 glow = glowColor * intensity;\n' +
      '            gl_FragColor = vec4( glow, 1 );\n' +
      '        }',
    side: THREE.DoubleSide,
    blending: THREE.AdditiveBlending,
    transparent: true,
    depthWrite: false,
  });

const addShadowedLight = (x, y, z, color, intensity, scene) => {
  let directionalLight = new THREE.DirectionalLight(color, intensity);
  directionalLight.position.set(x, y, z);
  scene.add(directionalLight);
  directionalLight.castShadow = true;
  let d = 1;
  directionalLight.shadow.camera.left = -d;
  directionalLight.shadow.camera.right = d;
  directionalLight.shadow.camera.top = d;
  directionalLight.shadow.camera.bottom = -d;
  directionalLight.shadow.camera.near = 0.1;
  directionalLight.shadow.camera.far = 10;
  directionalLight.shadow.mapSize.width = 2048;
  directionalLight.shadow.mapSize.height = 2048;
  directionalLight.shadow.bias = -0.002;
};

const solidMaterial = new THREE.MeshPhongMaterial({color: 0x606060, specular: 0x555555, shininess: 5});
solidMaterial.color.convertSRGBToLinear();

const materials = {
  solid: solidMaterial,
  xRay: xRayMaterial,
};

const gridHelperSize = bBox => {
  let longest = Math.max(bBox.max.x - bBox.min.x, bBox.max.y - bBox.min.y);

  // round up to 10 mm
  longest = Math.ceil(longest / 10) * 10;
  return longest;
};

const getBoundingBox = mesh => {
  if (mesh instanceof THREE.Group) {
    let bounds = {
      min: new THREE.Vector3(Infinity, Infinity, Infinity),
      max: new THREE.Vector3(-Infinity, -Infinity, -Infinity),
    };

    for (let child of mesh.children) {
      if (child instanceof THREE.Mesh && child.geometry) {
        child.geometry.computeBoundingBox();
        let worldMin = child.localToWorld(child.geometry.boundingBox.min);
        let worldMax = child.localToWorld(child.geometry.boundingBox.max);

        bounds.min.x = Math.min(bounds.min.x, worldMin.x);
        bounds.min.y = Math.min(bounds.min.y, worldMin.y);
        bounds.min.z = Math.min(bounds.min.z, worldMin.z);

        bounds.max.x = Math.max(bounds.max.x, worldMax.x);
        bounds.max.y = Math.max(bounds.max.y, worldMax.y);
        bounds.max.z = Math.max(bounds.max.z, worldMax.z);
      }
    }
    return bounds;

  } else {
    mesh.geometry.computeBoundingBox();
    return mesh.geometry.boundingBox;
  }
};

export {
  materials,
  addShadowedLight,
  gridHelperSize,
  getBoundingBox,
  xRayMaterial,
  solidMaterial,
};