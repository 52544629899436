import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CssBaseline, withStyles } from '@material-ui/core';
import { ErrorContext, parseApiError } from './util/errorHandler';
import { defaultStyles } from './util/styles';
import Loading from './components/Loading';
import ErrorDialog from './errors/ErrorDialog';
import routes from './util/routes';
import moment from 'moment';
import { Redirect } from 'react-router-dom';

import Home from './Home';
import Errors from './errors';

// set moment locale
moment.locale('en');

const styles = theme => ({
  ...defaultStyles(theme),
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDialogOpen: false,
      errorCode: '',
    };
  }

  showErrorDialog = errorCode => {
    this.setState({
      errorDialogOpen: true,
      errorCode: errorCode,
    });
  };

  closeDialog = () => {
    this.setState({
      errorDialogOpen: false,
      errorCode: '',
    });
  };

  handleApiError = (reason, history) => {
    // Function passed in react context, it is retrieved from context using
    // withErrorHandler higher order component. It should be called on axios
    // error. On critical error (i.e. no permission) user is redirected to
    // error page. If error is not critical just a dialog is shown.
    const redirectToErrorPage = errorCode => {
      history.push(routes.error);
      this.setState({
        errorCode: errorCode,
      });
    };

    const redirect = route => {
      history.push(route);
    };

    parseApiError(reason, redirectToErrorPage, this.showErrorDialog, redirect);
  };

  appRouter = () => {
    const {classes} = this.props;
    return (
        <div style={{flexGrow: 1}}>
          <BrowserRouter className={classes.content}>
            <Suspense fallback={<Loading/>}>
              <Switch>
                {/* errors */}
                <Route path={routes.error} component={Errors}/>

                {/* All other user pages with navbar and stuff */}
                <Route path={routes.home} component={Home}/>

                {/* route not found, redirect to error 404 page */}
                <Redirect to={routes.error404}/>
              </Switch>
            </Suspense>
          </BrowserRouter>
        </div>
    );
  };

  render() {
    const {classes} = this.props;
    return <div className={classes.root}>
      <CssBaseline/>
      <ErrorContext.Provider value={this.handleApiError}>
        {this.appRouter()}
      </ErrorContext.Provider>
      <ErrorDialog
          open={this.state.errorDialogOpen}
          errorCode={this.state.errorCode}
          onClose={this.closeDialog}
      />
    </div>;
  }
}

export default withStyles(styles)(App);