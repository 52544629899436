export default {
  home: '/',

  // Errors
  error: '/error',
  error400: '/error/400',
  error401: '/error/401',
  error403: '/error/403',
  error404: '/error/404',
  error500: '/error/500',
};