import React, { Component } from 'react';
import {
  Box,
  Grid,
  Dialog,
  LinearProgress,
  Typography,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  withStyles,
} from '@material-ui/core';
import { defaultStyles } from '../util/styles';
import Dropzone from 'react-dropzone';
import axios from 'axios';
import { withErrorHandler } from '../util/errorHandler';
import api from '../util/api';
import { stlFileUploadLimit } from '../util/constants';
import clsx from 'clsx';
import { grey } from '@material-ui/core/colors';
import PropTypes from 'prop-types';
import strings from '../strings';

const materials = [
  {
    name: strings.mixOne,
    density: 1.8, // kg/L
    printTime: 120, // seconds needed to print 1L of object
  },
  {
    name: strings.mixTwo,
    density: 2.1, // kg/L
    printTime: 150, // seconds needed to print 1L of object
  },
  {
    name: strings.mixThree,
    density: 1.5, // kg/L
    printTime: 1000, // seconds needed to print 1L of object
  },
];

const styles = (theme) => ({
  ...defaultStyles(theme),
  dropZone: {
    width: '100%',
    paddingTop: 64,
    paddingBottom: 96,
    transition: 'background-color 200ms linear',
    '&:focus': {
      outline: '-webkit-focus-ring-color auto 0px !important',
    },
    borderColor: grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
  },
  dragActive: {
    backgroundColor: grey[300],
  },
  dropHelperText: {
    color: grey[600],
    fontSize: 14,
  },
  progress: {
    backgroundColor: grey[300],
    transition: '300ms linear',
  },
  statName: {
    textTransform: 'uppercase',
  },
  viewer: {
    borderColor: 'transparent',
    borderWidth: 1,
    borderStyle: 'solid',
    [theme.breakpoints.up('md')]: {
      borderLeftColor: grey[300],
    },
    [theme.breakpoints.down('sm')]: {
      borderTopColor: grey[300],
    },
  },
});

class UploadViewer extends Component {
  requestToCancel = null;
  dropZoneRef = React.createRef();

  state = {
    dropRejected: false,
    uploadingProgress: {},
    isHub: true,
    materialIndex: 0,
  };

  uploadStlFiles = (files) => {
    const headers = {
      'Content-Type': 'multipart/form-data',
    };

    for (let file of files) {
      const id = file.path;

      let data = new FormData();
      data.append('file', file);

      let requestToCancel = null;
      const config = {
        // token to cancel request on component unmount
        cancelToken: new axios.CancelToken((c) => {
          requestToCancel = c;
        }),
        onUploadProgress: (progressEvent) =>
          this.setState((prevState) => {
            let uploadingProgress = prevState.uploadingProgress;

            uploadingProgress[id].progress = progressEvent.loaded;

            return {
              uploadingProgress: uploadingProgress,
            };
          }),
      };

      this.setState((prevState) => {
        let uploadingProgress = prevState.uploadingProgress;

        uploadingProgress[id] = {
          file: file,
          progress: 0,
          requestToCancel: requestToCancel,
          done: false,
          uid: null,
          key: null,
        };

        return {
          uploadingProgress: uploadingProgress,
        };
      });

      api.post(`/files`, data, {
        headers: headers,
        config: config,
        onSuccess: (response) => {
          // file was uploaded
          this.setState(
            (prevState) => {
              let uploadingProgress = prevState.uploadingProgress;

              uploadingProgress[id].done = true;
              uploadingProgress[id].uid = response.data.uid;
              uploadingProgress[id].depth = response.data.depth;
              uploadingProgress[id].height = response.data.height;
              uploadingProgress[id].width = response.data.width;
              uploadingProgress[id].volume = response.data.volume;

              return {
                uploadingProgress: uploadingProgress,
              };
            },
            () => {
              // notify parent about file and default material
              this.props.onFileChanged(
                this.state.uploadingProgress[
                  Object.keys(this.state.uploadingProgress)[0]
                ]
              );
              this.setState({ uploadingProgress: {}, materialIndex: 0 });
              this.props.onMaterialChanged(materials[this.state.materialIndex], this.state.materialIndex);
            }
          );
        },
        onError: (reason) => {
          this.props.handleApiError(reason, this.props.history);
        },
      });
    }
  };

  componentWillUnmount() {
    for (let k in this.state.uploadingProgress) {
      if (this.state.uploadingProgress[k].requestToCancel) {
        this.state.uploadingProgress[k].requestToCancel();
      }
    }
  }

  uploading = () => {
    for (let k in this.state.uploadingProgress) {
      if (!this.state.uploadingProgress[k].done) return true;
    }
    return false;
  };

  uploadFinished = () => {
    return (
      !this.uploading() && Object.keys(this.state.uploadingProgress).length > 0
    );
  };

  onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0 || this.uploading()) return;
    this.uploadStlFiles(acceptedFiles);
  };

  onDropRejected = (rejectedFiles) => {
    this.setState({
      dropRejected: true,
    });
  };

  dropRejectedDialog = () => {
    return (
      <Dialog
        open={true}
        onClose={() => this.setState({ dropRejected: false })}
      >
        <DialogTitle>{strings.stlRejected}</DialogTitle>
        <DialogContent>{strings.stlRejectedMessage}</DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ dropRejected: false })}>
            {strings.close}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  getUploadingProgress = () => {
    const { classes } = this.props;
    let elements = [];

    elements.push(
      <Grid item xs={12} key="something">
        <Box pt={2} />
      </Grid>
    );

    for (let k in this.state.uploadingProgress) {
      const e = this.state.uploadingProgress[k];

      elements.push(<Grid item xs={12} key={`k-${k}`} />);
      elements.push(
        <Grid item xs={10} sm={6} md={4} lg={3} key={`element-${k}`}>
          <Box pt={2}>
            <Typography
              className={clsx(classes.dropHelperText, classes.textCenter)}
              variant="h6"
            >
              {e.file.name}
            </Typography>
            <Box pt={1}>
              <LinearProgress
                variant="determinate"
                value={Math.min((e.progress / e.file.size) * 100, 100)}
                className={clsx(classes.w100, classes.progress)}
              />
            </Box>
          </Box>
        </Grid>
      );
    }

    return elements;
  };

  triggerDrop = () => {
    if (!this.dropZoneRef || !this.dropZoneRef.current) return;
    this.dropZoneRef.current.open();
  };

  render() {
    const { classes } = this.props;

    return (
      <Dropzone
        multiple={false}
        onDrop={this.onDrop}
        ref={this.dropZoneRef}
        noClick
        className={classes.dropZone}
        onDropRejected={this.onDropRejected}
        accept=".stl"
        maxSize={stlFileUploadLimit}
      >
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps()}
            className={
              isDragActive
                ? clsx(classes.dragActive, classes.dropZone)
                : classes.dropZone
            }
          >
            <input {...getInputProps()} />
            <Grid container direction="row" justify="center">
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  className={classes.w100}
                >
                  {!this.uploading() ? (
                    <Grid item>
                      <Box pt={5}>
                        <Button
                          variant="contained"
                          disableElevation
                          color="primary"
                          onClick={this.triggerDrop}
                        >
                          <Grid container direction="row" justify="center">
                            {strings.uploadButton}
                          </Grid>
                        </Button>
                      </Box>
                    </Grid>
                  ) : null}
                  <Grid item xs={12} />
                  <Grid item>
                    <Box pt={1}>
                      <Typography
                        className={clsx(
                          classes.dropHelperText,
                          classes.textCenter
                        )}
                        variant="h6"
                      >
                        {this.uploading()
                          ? strings.uploadingFiles
                          : strings.orDropAnywhere}
                      </Typography>
                    </Box>
                  </Grid>
                  {this.uploading() ? this.getUploadingProgress() : null}
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
      </Dropzone>
    );
  }
}

UploadViewer.propTypes = {
  onMaterialChanged: PropTypes.func.isRequired,
  onFileChanged: PropTypes.func.isRequired,
};

export default withErrorHandler(withStyles(styles)(UploadViewer));
