import axios from 'axios';
import { apiUrl } from './constants';

const request = method => async (
  url,
  data = {},
  {
    onSuccess,
    onError,
    config,
  } = {
    onSuccess: () => {
    },
    onError: () => {
    },
    admin: false
  }
) => {
  try {
    const response = await axios({
      method: method,
      url: apiUrl + url,
      data: data,
      ...config
    });
    if (onSuccess !== undefined) onSuccess(response);
  } catch (error) {
    if (onError !== undefined) onError(error);
  }
};

export default {
  get: request('get'),
  post: request('post'),
  delete: request('delete'),
  put: request('put')
};