import React from 'react';
import { withErrorHandler } from '../util/errorHandler';
import {
  Grid,
  withStyles,
  Box,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { defaultStyles } from '../util/styles';
import PropTypes from 'prop-types';
import { materials } from './viewerHelpers';
import StlImg from './StlImg';
import { Button } from '@material-ui/core';
import strings from '../strings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { faSyncAlt } from '@fortawesome/pro-light-svg-icons/faSyncAlt';
import { faExpandArrows } from '@fortawesome/pro-light-svg-icons/faExpandArrows';
import { faSearchPlus } from '@fortawesome/pro-light-svg-icons/faSearchPlus';
import { faHome } from '@fortawesome/pro-light-svg-icons/faHome';

const styles = (theme) => ({
  ...defaultStyles(theme),
  toolbar: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
  },
  positionRelative: {
    position: 'relative',
  },
  closeButton: {
    minWidth: 55,
    minHeight: 55,
  },
});

class StlViewer extends React.Component {
  viewerRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      material: materials.solid,
      url: props.url,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.url !== nextProps.url) {
      return {
        url: nextProps.url,
      };
    }
    return null;
  }

  normalView = () => {
    this.setState({ material: materials.solid }, () => {
      this.viewerRef.setMaterial(materials.solid);
    });
  };

  xRayView = () => {
    this.setState({ material: materials.xRay }, () => {
      this.viewerRef.setMaterial(materials.xRay);
    });
  };

  render() {
    const { classes } = this.props;

    let tooltipContent = (
      <Box p={1}>
        <Grid container direction="row" spacing={1} justify="center">
          <Grid item>
            <FontAwesomeIcon icon={faSyncAlt} size="2x" />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.textCenter}>
              {strings.rotateViewerInstructions}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box pt={1} />
          </Grid>
          <Grid item>
            <FontAwesomeIcon icon={faExpandArrows} size="2x" />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.textCenter}>
              {strings.moveViewerInstructions}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box pt={1} />
          </Grid>
          <Grid item>
            <FontAwesomeIcon icon={faSearchPlus} size="2x" />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.textCenter}>
              {strings.zoomViewerInstructions}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    );

    const toolbar = (
      <div className={classes.toolbar}>
        <Box pl={2} pr={1} py={1}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid container direction="row" spacing={1} alignItems="center">
                <Grid item>
                  <Button
                    onClick={this.normalView}
                    variant={
                      this.state.material === materials.solid
                        ? 'outlined'
                        : 'text'
                    }
                  >
                    {strings.materialSolid}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={this.xRayView}
                    variant={
                      this.state.material === materials.xRay
                        ? 'outlined'
                        : 'text'
                    }
                  >
                    {strings.materialXRay}
                  </Button>
                </Grid>
                <Grid item>
                  <Tooltip placeholder="bottom" title={strings.recenterViewer}>
                    <IconButton
                      disableRipple
                      onClick={() => this.viewerRef.resetOrientation()}
                    >
                      <FontAwesomeIcon icon={faHome} />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip placement="bottom" title={tooltipContent}>
                    <IconButton disableRipple>
                      <FontAwesomeIcon icon={faQuestionCircle} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    );

    return (
      <div className={classes.positionRelative}>
        <div>
          <StlImg
            aspectRatio={0.6}
            innerRef={(ref) => (this.viewerRef = ref)}
            enabled
            gridHelper
            progress
            url={this.state.url}
          />
        </div>
        {toolbar}
      </div>
    );
  }
}

StlViewer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default withErrorHandler(withStyles(styles)(StlViewer));
