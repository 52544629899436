import LocalizedStrings from 'react-localization';

const languages = ['en'];

const strings = new LocalizedStrings({
  en: {
    // app name
    appName: 'Wool2Loop',

    // sites
    order: 'Order',
    partners: 'Partners',
    project: 'Project',
    instructions: 'Step 1: Select preloaded 3D models or upload your own',
    instructionsStep2: 'Step 2: Select object material',
    objectInstructions: 'You can try and change another 3D model or upload your own below',
    submitInstructions: 'You can submit your 3D model to the selected partner and make an inquiry',

    // materials
    mixOne: 'MIX 1 - 50% stone wool, 50% glass wool',
    mixTwo: 'MIX 2 - 25% stone wool, 75% glass wool',
    mixThree: 'MIX 3 - 75% stone wool, 25% glass wool',

    // misc
    decimalSeparator: '.',
    thousandSeparator: ',',
    millimeter: 'mm',
    micrometer: 'µm',
    percentSign: '%',
    timesSign: 'x',
    currency: '€',
    home: 'Home',
    close: 'Close',
    initializing3dViewer: 'Initializing 3D viewer',
    uploadButton: 'Upload STL file',
    fileName: 'File name',
    submit: 'Submit',
    recipientCompany: 'Recipient company',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'E-mail',
    phone: 'Phone',
    description: 'Description',
    country: 'Country',
    formSubmitted: 'Form successfully submitted.',
    volume: 'Volume',
    mass: 'Mass',
    printTime: 'Print time',
    dimensions: 'Dimensions',
    company: 'Company',

    // action buttons
    ok: 'Ok',
    cancel: 'Cancel',
    save: 'Save',
    add: 'Add',

    // Dialogs
    error: 'Error',

    // 3d viewer
    materialSolid: 'Solid',
    materialWireframe: 'Wireframe',
    materialXRay: 'X-Ray',
    rotateViewerInstructions: 'Use left mouse button to rotate object.',
    moveViewerInstructions: 'Right mouse button moves the object around.',
    zoomViewerInstructions: 'Zoom in or out by scrolling.',
    recenterViewer: 'Re-Center Viewer',
    orDropAnywhere: 'or drag & drop anywhere.',
    uploadingFiles: 'Uploading file ...',
    stlRejected: 'STL upload rejected',
    stlRejectedMessage: 'Currently only "stl" files under 200 MB are supported.',

    // slicing error (if sliced price is zero)
    slicingError: 'Slicing error',
    slicingErrorDesc:
      'We were not able to correctly slice one or more of the files. You can still create an order',
    orderSlicingErrorText:
      'We were not able to correctly slice one or more of the files, so the estimated price could be incorrect.',
    slicingFileFailed: fileName => `Unable to slice file${fileName ? ' ' + fileName : ''}.`,

    filesAreStillUploading: 'There are still some files uploading',
    pleaseSelectMaterialAndMaterialType:
      'Please select material and material type',
    pleaseUploadFileToPrint: 'Please upload a file to print',
    pleaseSelectMaterialColorForEachPiece:
      'Please select material color for each piece',
    pleaseWaitForSlicing: 'Please wait for slicing to finish',

    // SERVER ERRORS
    // THIS NEEDS TO BE UPDATED WHEN NEW ERROR TYPE IS ADDED ON SERVER
    errorInvalidJson: 'Invalid JSON',
    errorNotAuthorized: 'Unknown Error', // not authorized za kaj?
    errorInvalidToken: 'Unknown Error',
    errorInvalidCredentials: 'Unknown Error',
    errorIdNotNumber: 'Unknown Error',
    errorTokenGeneration: 'Unknown Error',
    errorFileNotFound: 'Unknown Error', // Kater file, kdo to vidi?
    errorNoPermission: 'Unknown Error', // za kaj nimas permissiona?
    errorCouldNotDisplayFile: 'Unknown Error',
    errorFileUpload: 'An error occurred while uploading your file. Please try again.',
    errorUserNotHub: 'User not found.', // Kdo je iskal tega userja?
    errorHubExists: 'Unknown Error', // Kdaj se to zgodi?
    errorHubSave: 'Unknown Error', // Kdaj naj bi se to zgodil? Ko hoče user shranit nastavitve za svoj hub?
    errorHubNotFound: 'Unknown Error', // A to se pojavi, če bi nekdo hotu v url utipkat id nezga huba, ki sploh ne obstaja?
    errorHubMachineNotFound: 'Unknown Error',
    errorHubMachineMismatch: 'Unknown Error',
    errorHubMachineDisplay: 'Unknown Error',
    errorHubMaterialDisplay: 'Unknown Error',
    errorMachineNotFound: 'Unknown Error',
    errorMachineSave: 'An error occurred while saving machine.',
    errorMaterialNotFound: 'Unknown Error',
    errorMaterialSave: 'An error occurred while saving material.',
    errorMaterialTypeNotFound: 'Unknown Error',
    errorMaterialTypeSave: 'Unknown Error',
    errorMaterialTypeMismatch: 'Unknown Error',
    errorMaterialColorNotFound: 'Unknown Error',
    errorMaterialColorSave: 'Unknown Error',
    errorMaterialColorMismatch: 'Unknown Error',
    errorSlicer: 'Oops ... slicer has some problems. Please try submitting your one more time.',
    errorSlicedObjectNotFound: 'Unknown Error',
    errorOrderNoHubs: 'Unknown Error', // A to je takrat, ko se za neke uploadane 3D modele ne najde nobengga primernega huba, ki bi to lahko naprinto?
    errorOrderHubsQuery: 'Unknown Error',
    errorOrderPriceCalculation: 'An error occurred while calculating price for your order.',
    errorOrderSortParameter: 'Unknown Error',
    errorOrderAddressRequired: 'Unknown Error',
    errorOrderAddressFormat: 'Unknown Error',
    errorHubRequired: 'Unknown Error', // required za kaj?
    errorOrderLocalPickupRequired: 'Local pickup is required for this order.', // A to kao da je local pickup required za nek order?
    errorOrderRecipientRequired: 'Unknown Error',
    errorOrderHubLocalPickupUnsupported: 'Sorry, but local pickup is not supported by this hub.', // kdo dobi ta error? cutomer ko hoče pri hubu zbrat local pickup pa ga ta hub ne omogoča?
    errorOrderSave: 'An error occurred while saving your order. Please try again', // Kaj naj bi ta save orderja pomenu?
    errorOrderNotFound: 'Unknown Error',
    errorOrderReceiverNotNull: 'Unknown Error',
    errorOrderMessageTypeInvalid: 'Unknown Error',
    errorOrderEventSave: 'Unknown Error', // Kašen event je to mišlen?
    errorOrderConnectionUpgradeFail: 'Unknown Error',
    errorInvalidSortParam: 'Unknown Error',
    errorOrderReviewExists: 'Unknown Error', // Kaj naj bi to pomenil?
    errorOrderReviewSave: 'An error occurred while saving your review. Please try again.',
    errorOrderReviewNotFound: 'Unknown Error',
    errorOrderInvalidState: 'Unknown Error',
    errorOrderItemNotFound: 'Unknown Error', // kje item ni foundan?
    errorOrderItemMismatch: 'Unknown Error', // kak mismatch?
    errorOrderTransactionSave: 'Unknown Error', // Kakšen transaction? a to je od stripe-a, če dobiš kak false nazaj?
    errorPayoutOrderHubMismatch: 'Unknown Error',
    errorPayoutSave: 'Unknown Error',
    errorOrderPayed: 'Unknown Error', // a to je spet kej od Strip-a?
    errorPayoutOrderInvalidState: 'Unknown Error',
    errorPayoutDisplay: 'Unknown Error',
    errorVATNotFound: 'Unknown Error',
    errorVATSave: 'Unknown Error',
    errorReviewReportExists: 'Report for this review already exists.',
    errorReviewReportSave: 'Changing review report failed',
    errorReviewReportNotFound: 'Could not find report for this review',
    errorSlicerCapacity: 'Slicer on our website rejected slicing as there are too many models slicing already. Please try again later.',
    errorSlicerTrianglesLimit: 'Unable to process this file. Number of triangles in stl is too large.',
    errorSTLRead: 'Invalid stl file.',
    // Bonus error - if none of the above
    errorUnknown: 'Unknown Error',

    privacyPolicy: 'Privacy Policy',
    termsAndConditions: 'Terms and Conditions',

    // error codes
    '400': 'Bad request.',
    '401': 'You are trying to access a page without sufficient authorization.',
    '403': 'Access to this page is forbidden.',
    '404': 'Requested page could not be found.',
    '500': 'Internal server error.',
  },
});

strings.setLanguage('en');

export { languages };
export default strings;