import React, { Fragment } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import {
  Grid,
  LinearProgress,
  Box,
  Typography,
  withStyles,
} from '@material-ui/core';
import { defaultStyles } from '../util/styles';
import PropTypes from 'prop-types';
import {
  solidMaterial,
  addShadowedLight,
  gridHelperSize,
  getBoundingBox,
  materials,
  xRayMaterial,
} from './viewerHelpers';
import clsx from 'clsx';

const styles = (theme) => ({
  ...defaultStyles(theme),
  parent: {
    position: 'relative',
    outline: 'none !important',
    '& *': {
      outline: 'none !important',
    },
  },
  child: {
    transition: 'opacity 300ms linear',
  },
  progressContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  invisible: {
    opacity: 0,
  },
});

const defaultState = {
  loading: true,
  progress: 0,
  material: solidMaterial,
};

class StlImg extends React.Component {
  ref = React.createRef();
  scene = new THREE.Scene();
  sceneRef = null;
  renderer = null;
  gridHelper = null;
  bBox = null;

  constructor(props) {
    super(props);
    this.state = {
      parentId: Math.random().toString(36).substring(7), // random id to prevent multiple viewers in DOM clashing
      aspectRatio: this.props.aspectRatio,
      ...defaultState,
    };
  }

  componentDidMount() {
    this.initViewer();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.url !== prevProps.url) {
      document.getElementById(this.state.parentId).innerHTML = '';
      this.setState({ ...defaultState });
      this.initViewer();
    }
  }

  componentWillUnmount() {
    if (this.renderer) this.renderer.renderLists.dispose();
    document.getElementById(this.state.parentId).innerHTML = '';
  }

  getWidth = (ref) => {
    return ref.current.offsetWidth;
  };

  setMaterial = (material) => {
    switch (material) {
      case materials.solid:
        this.scene.overrideMaterial = solidMaterial;
        break;
      case materials.xRay:
        this.scene.overrideMaterial = xRayMaterial;
        break;
      default:
        this.scene.overrideMaterial = solidMaterial;
        break;
    }
    this.renderFrame();
  };

  // move viewer to center with default orientation
  resetOrientation = () => {
    let c = 2;
    this.camera.position.x = (this.bBox.max.x - this.bBox.min.x) * c;
    this.camera.position.y = (this.bBox.max.y - this.bBox.min.y) * c;
    this.camera.position.z = (this.bBox.max.z - this.bBox.min.z) * 3;
    this.camera.near = 1;
    this.camera.far =
      Math.max(
        this.bBox.max.x - this.bBox.min.x,
        this.bBox.max.y - this.bBox.min.y,
        this.bBox.max.z - this.bBox.min.z
      ) * 5;
    this.camera.updateProjectionMatrix();

    this.controls.target = new THREE.Vector3(
      0,
      (this.bBox.max.z - this.bBox.min.z) / 2,
      0
    );
    this.controls.update();
  };

  onProgress = (event) => {
    // only set state if needed for performance
    if (this.props.progress) {
      // if length is not computable, set state to -1 only once
      if (this.state.progress !== -1 && !event.lengthComputable)
        this.setState({ progress: -1 });
      else if (event.lengthComputable)
        this.setState({ progress: event.loaded / event.total });
    }
  };

  renderFrame = () => {
    if (this.sceneRef != null) this.renderer.render(this.sceneRef, this.camera);
  };

  initViewer() {
    // frame dimensions
    let width = this.getWidth(this.ref);
    let height = width * this.state.aspectRatio;

    // scene
    this.scene = new THREE.Scene();
    this.scene.overrideMaterial = solidMaterial;

    // add camera
    this.camera = new THREE.PerspectiveCamera(45, width / height, 1, 1000);

    // renderer
    this.renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    this.renderer.setSize(width, height);
    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    // damping is disabled due to performance issues with continuous animation loop
    // this.controls.enableDamping = true;

    // currently only rendering on controls changed for performance reasons
    this.controls.addEventListener('change', this.renderFrame);
    this.controls.update();
    this.controls.enabled = this.props.enabled;

    // fix ui bug
    this.renderer.domElement.style.marginBottom = '-5px';

    // add light
    this.scene.add(new THREE.HemisphereLight(0x535359, 0x111122));
    this.scene.add(new THREE.AmbientLight(0xe0e0e0)); // soft white light
    addShadowedLight(1.5, 1.2, -1, 0xbbaa99, 1, this.scene);
    addShadowedLight(-0.3, 2.5, 1.3, 0xffffff, 1.35, this.scene);

    // renderer optimization
    this.renderer.gammaInput = true;
    this.renderer.gammaOutput = true;
    this.renderer.shadowMap.enabled = true;
    this.renderer.gammaFactor = 2.2;

    // add to dom
    document
      .getElementById(this.state.parentId)
      .appendChild(this.renderer.domElement);

    let loader = new STLLoader();
    this.sceneRef = this.scene;

    let mesh;

    loader.load(
      this.props.url,
      (geometry) => {
        this.setState({
          loading: false,
        });

        mesh = new THREE.Mesh(geometry, solidMaterial);

        // shadows
        mesh.castShadow = true;
        mesh.receiveShadow = true;

        // // move to center
        this.bBox = getBoundingBox(mesh);

        // notify parent about bbox
        if (this.props.onCalculateBoundingBox)
          this.props.onCalculateBoundingBox(this.bBox);

        mesh.rotateX(Math.PI * -0.5);
        mesh.translateX(-((this.bBox.max.x + this.bBox.min.x) / 2));
        mesh.translateY(-((this.bBox.max.y + this.bBox.min.y) / 2));
        mesh.translateZ(-this.bBox.min.z);

        this.gridHelper = new THREE.GridHelper(
          gridHelperSize(this.bBox),
          10,
          0xffffff,
          0xbdbdbd
        );
        if (this.props.gridHelper) this.scene.add(this.gridHelper);

        // position camera
        this.resetOrientation();

        this.sceneRef.add(mesh);
        this.renderFrame();
      },
      this.onProgress
    );

    // update
    let animate = () => {
      // currently only rendering on controls changed for performance reasons
      // requestAnimationFrame(animate);
      // if (mesh && this.state.animate) this.scene.rotateY(0.005);
      if (this.props.enabled) this.controls.update();
      this.renderFrame();
    };

    animate();
  }

  getProgress = () => {
    if (this.props.progress && this.state.progress !== -1) {
      return (
        <Fragment>
          <Grid item>
            <Typography variant="h6">
              {Math.round(this.state.progress * 100)} %
            </Typography>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={10} md={8} lg={5} xl={4}>
            <Box pt={1}>
              <LinearProgress
                value={this.state.progress * 100}
                variant="determinate"
              />
            </Box>
          </Grid>
        </Fragment>
      );
    } else {
      return (
        <Grid item>
          <LinearProgress variant="indeterminate" />
        </Grid>
      );
    }
  };

  render() {
    const { classes } = this.props;
    if (this.scene) this.scene.overrideMaterial = this.state.material;

    return (
      <div className={clsx(classes.parent, classes.w100)}>
        <div
          id={this.state.parentId}
          ref={this.ref}
          className={
            this.state.loading
              ? clsx(classes.w100, classes.child, classes.invisible)
              : clsx(classes.w100, classes.child)
          }
        />
        {this.state.loading ? (
          <Grid
            container
            direction="column"
            className={classes.progressContainer}
            justify="center"
          >
            <Grid item>
              <Grid container direction="row" justify="center">
                {this.getProgress()}
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </div>
    );
  }
}

StlImg.propTypes = {
  url: PropTypes.string,
  aspectRatio: PropTypes.number,
  animate: PropTypes.bool,
  enabled: PropTypes.bool,
  progress: PropTypes.bool,
};

export default withStyles(styles)(StlImg);
