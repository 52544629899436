import React, { Component } from 'react';
import {
  Box,
  withStyles,
  Container,
  Grid,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { defaultStyles } from './util/styles';
import UploadViewer from './components/UploadViewer';
import strings from './strings';
import { withErrorHandler } from './util/errorHandler';
import { withRouter } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import { countries } from './util/countries';
import { captchaV2SiteKey } from './util/constants';
import clsx from 'clsx';
import api from './util/api';

// Pictures of example models
import acoustic from './res/img/model_examples/acoustic.png';
import column from './res/img/model_examples/column.jpg';
import benchy from './res/img/model_examples/benchy.jpeg';

// Partner logos
import xtree from './res/img/partners/xtreeE.png';
import tudelft from './res/img/partners/TUdelft.png';
import logo_404 from './res/img/partners/404-logo.svg';

import ModelViewer from './components/ModelViewer';

const recipients = [
  { name: 'XTREEE', email: 'pisarna@404.si' },
  { name: 'TU DELFT', email: 'pisarna@404.si' },
  { name: 'Zavod 404', email: 'pisarna@404.si' },
];

const exampleModels = [
  {
    name: 'Acoustic panel',
    description: 'A simple symmetrical acoustic panel design.',
    uid: '21caf343dd20787f22161da39278772d',
    depth: 36.00000286102295,
    height: 459.7057800292969,
    volume: 5043799.61083681,
    width: 459.7057800292969,
    image: acoustic,
  },
  {
    name: 'Doric column',
    description: 'Classical Greek Doric column.',
    uid: 'ddf44b608d9a25e8b06738ad08fa5513',
    depth: 416.55999755859375,
    height: 1945.6400146484375,
    volume: 121565376.02428815,
    width: 416.55999755859375,
    image: column,
  },
  {
    name: 'Benchy',
    description: 'The iconic first 3D print. Supersized for your convenience.',
    uid: 'ca40ac22f8d0a3bae47754afa8efeb15',
    depth: 465.05999755859375,
    height: 720,
    volume: 52483041.231905356,
    width: 900.0149841308594,
    image: benchy,
  },
];

const partners = [
  {
    name: 'XTREEE',
    description:
      'Xtreee is a platform that enables developers, designers, architects, engineers, construction companies and manufacturers to design and produce optimized structuresthants to its advanced large-scale 3D printing technology.',
    image: xtree,
  },
  {
    name: 'TU DELFT',
    description:
      'The Microlab at TUDELFT is a fundamental research lab in which the projects have strong links with experimenting & modelling. Cement-based systems (e.g. concrete) is our primary area of research. Co-operation in research on other building materials and production methods such as 3D printing can be discussed.',
    image: tudelft,
  },
  {
    name: 'Zavod 404',
    description:
      'A private research facility dedicated to design, construct and evaluate 3D printing systems for various areas of implementation. We specialize in development of additive deposition machinery for the laboratory and small scale production.',
    image: logo_404,
  },
];

class Home extends Component {
  state = {
    material: null,
    materialIndex: 0,
    file: null,
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    countryId: 0,
    captchaValue: null,
    phone: '',
    content: '',
    recipientId: 0,
    loading: false,
    submitted: false,
  };

  valid = () => {
    const {
      firstName,
      lastName,
      email,
      company,
      countryId,
      phone,
      content,
      captchaValue,
    } = this.state;
    if (firstName.length === 0) return false;
    if (lastName.length === 0) return false;
    if (email.length === 0) return false;
    if (company.length === 0) return false;
    if (countryId < 0) return false;
    if (phone.length === 0) return false;
    if (content.length === 0) return false;
    if (captchaValue == null) return false;
    return true;
  };

  getCard = (i, data, partner) => {
    return (
      <Card id={i} style={{ minHeight: partner && '420px' }}>
        <CardActionArea
          style={{ minHeight: partner && '420px' }}
          onClick={
            partner
              ? () => this.setRecipient(i)
              : () => this.getSelectedExample(i)
          }
        >
          <Box p={partner && 3}>
            <CardMedia
              component="img"
              alt={data.name}
              height={partner ? '100%' : 280}
              image={data.image}
              title={data.name}
            />
          </Box>
          <CardContent style={{ minHeight: '140px' }}>
            <Typography gutterBottom variant="h5" component="h2">
              {data.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {data.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  setRecipient = (id) => {
    this.setState({
      recipientId: id,
    });
  };

  getSelectedExample = (id) => {
    this.setState({
      file: exampleModels[id],
    });
  };

  getRecipientSelect = () => {
    const { classes } = this.props;
    return (
      <FormControl className={classes.w100}>
        <InputLabel id="recipientCompany" className={classes.w100}>
          {strings.recipientCompany}
        </InputLabel>
        <Select
          className={classes.w100}
          labelId="recipientCompany"
          value={this.state.recipientId}
          onChange={(e) => this.setState({ recipientId: e.target.value })}
        >
          {recipients.map((recipient, index) => (
            <MenuItem value={index} key={index}>
              {recipient.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  getCountrySelect = () => {
    const { classes } = this.props;
    return (
      <FormControl className={classes.w100}>
        <InputLabel id="country" className={classes.w100}>
          {strings.country}
        </InputLabel>
        <Select
          className={classes.w100}
          labelId="country"
          value={this.state.countryId}
          onChange={(e) => this.setState({ countryId: e.target.value })}
        >
          {countries.map((country, index) => (
            <MenuItem value={index} key={index}>
              {country.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  getTextField = (stateKey, inputType, label, multiline) => {
    const { classes } = this.props;
    return (
      <Grid item xs={12} md={9} lg={7}>
        <TextField
          label={label}
          type={inputType}
          className={classes.w100}
          value={this.state[stateKey]}
          onChange={(e) => this.setState({ [stateKey]: e.target.value })}
          multiline={multiline}
          rows={multiline ? 5 : 1}
        />
      </Grid>
    );
  };

  getCaptcha = () => {
    return (
      <Grid item xs={12} md={9} lg={7}>
        <ReCAPTCHA
          sitekey={captchaV2SiteKey}
          onChange={(value) => this.setState({ captchaValue: value })}
        />
      </Grid>
    );
  };

  submit = () => {
    this.setState({ loading: true });

    const data = {
      recipient: recipients[this.state.recipientId].email,
      material: this.state.material?.name,
      file_uid: this.state.file?.uid,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      company: this.state.company,
      country: countries[this.state.countryId].Name,
      phone: this.state.phone,
      content: this.state.content,
      captcha: this.state.captchaValue,
    };

    api.post('/orders', data, {
      onSuccess: (response) =>
        this.setState({ loading: false, submitted: true }),
      onError: (reason) => {
        this.setState({ loading: false, submitted: false });
        this.props.handleApiError(reason, this.props.history);
      },
    });
  };

  getForm = () => {
    const { classes } = this.props;

    if (this.state.loading)
      return (
        <Box p={5} className={classes.border}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item>
              <Box p={3}>
                <CircularProgress />
              </Box>
            </Grid>
          </Grid>
        </Box>
      );

    if (this.state.submitted)
      return (
        <>
          <Grid item xs={12}>
            <Box p={5} mt={3} className={classes.border}>
              <Typography
                variant="h6"
                className={clsx(classes.w100, classes.textCenter)}
              >
                {strings.formSubmitted}
              </Typography>
            </Box>
          </Grid>
        </>
      );

    return (
      <>
        <Grid item xs={12}>
          <Box pt={5}>
            <Typography variant="h6" className={classes.titleMain}>
              {strings.submitInstructions}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box p={3} className={classes.border} mt={5}>
            <Grid container direction="row" justify="space-between" spacing={3}>
              <Grid item xs={12} md={9} lg={7}>
                {this.getRecipientSelect()}
              </Grid>

              {this.getTextField('firstName', 'text', strings.firstName, false)}
              {this.getTextField('lastName', 'text', strings.lastName, false)}
              {this.getTextField('email', 'email', strings.email, false)}
              {this.getTextField('company', 'text', strings.company, false)}
              <Grid item xs={12} md={9} lg={7}>
                {this.getCountrySelect()}
              </Grid>
              {this.getTextField('phone', 'text', strings.phone, false)}
              {this.getTextField('content', 'text', strings.description, true)}
              {this.getCaptcha()}
              <Grid item xs={12}>
                <Grid container direction="row" justify="flex-end">
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Button
                      variant="contained"
                      disableElevation
                      color="primary"
                      className={classes.w100}
                      disabled={!this.valid()}
                      onClick={this.submit}
                    >
                      {strings.submit}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </>
    );
  };

  render() {
    const { material, file } = this.state;
    const { classes } = this.props;

    return (
      <Container maxWidth="lg">
        <Box py={5}>
          <Grid container direction="row">
            <Grid item xs={12}>
              <Box mb={5}>
                <Typography variant="h6" className={classes.titleMain}>
                  {!file && !material
                    ? strings.instructions
                    : strings.objectInstructions}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                {exampleModels.map((item, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    {this.getCard(index, item, false)}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box pb={5} mt={5}>
                {this.state.file && (
                  <>
                    <Grid item xs={12}>
                      <Box mb={3}>
                        <Typography variant="h6" className={classes.titleMain}>
                          {strings.instructionsStep2}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <ModelViewer
                        file={this.state.file}
                        materialIndex={this.state.materialIndex}
                        onMaterialChanged={(material) =>
                          this.setState({ material: material })
                        }
                      />
                    </Grid>
                  </>
                )}
                <Box mt={5}>
                  <UploadViewer
                    onFileChanged={(file) => this.setState({ file: file })}
                    onMaterialChanged={(material, index) => {
                      this.setState({
                        material: material,
                        materialIndex: index,
                      });
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mb={3}>
                <Typography variant="h6" className={classes.titleMain}>
                  {strings.partners}
                </Typography>
              </Box>
              <Grid container spacing={2}>
                {partners.map((partner, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    {this.getCard(index, partner, true)}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {this.getForm()}
          </Grid>
        </Box>
      </Container>
    );
  }
}

export default withErrorHandler(withRouter(withStyles(defaultStyles)(Home)));
