import React, { Component } from 'react';
import strings from '../strings';
import { defaultStyles } from '../util/styles';
import { withRouter } from 'react-router-dom';
import { Container, withStyles, Grid, Typography, Box, Button} from '@material-ui/core';
import PropTypes from 'prop-types';
import Image404 from '../res/img/errors/404.jpg';
import Image500 from '../res/img/errors/500.jpg';
import routes from '../util/routes';

const styles = theme => ({
  ...defaultStyles(theme),
  uppercase: {
    textTransform: 'uppercase',
  },
});

class ErrorPage extends Component {
  render() {
    const {errorCode, classes} = this.props;
    // get error message from error code (message is saved in localized strings)
    let errorMessage = strings[errorCode];
    if (errorMessage == null || errorMessage === '') {
      errorMessage = strings['errorUnknown'];
    }

    const filterOpacity = 0.68;

    return <div style={{
      width: '100vw',
      height: '100vh',
      background: `linear-gradient(
                    rgba(0, 0, 0, ${filterOpacity}),
                    rgba(0, 0, 0, ${filterOpacity})
                    ), url(${errorCode === 404 ? Image404 : Image500})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }}>
      <Container maxWidth='sm'>
        <Box pt={20}>
          <Grid container direction='row' justify='center' alignItems='center' className={classes.container}
                spacing={2}>
            <Grid item>
              <Typography variant='h1' className={classes.textCenter}>
                {errorCode}
              </Typography>
            </Grid>
            <Grid item xs={12}/>
            <Grid item>
              <Typography variant='h6' className={classes.textCenter}>
                {errorMessage}
              </Typography>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={12}/>
            <Grid item>
              <Button variant='outlined' className={classes.uppercase}
                      onClick={() => this.props.history.push(routes.home)}>
                <Box p={1}>
                  {strings.takeMeHome}
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>;
  }
}

ErrorPage.propTypes = {
  errorCode: PropTypes.number.isRequired,
};

export default withRouter(withStyles(styles)(ErrorPage));