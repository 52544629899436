import React, { Component } from 'react';
import StlViewer from '../viewer/StlViewer';
import { Box, Button, Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import strings from '../strings';
import { withErrorHandler } from '../util/errorHandler';
import { defaultStyles } from '../util/styles';
import { grey } from '@material-ui/core/colors';
import { apiUrl } from '../util/constants';

const styles = (theme) => ({
  ...defaultStyles(theme),
  dragActive: {
    backgroundColor: grey[300],
  },
  dropHelperText: {
    color: grey[600],
    fontSize: 14,
  },
  progress: {
    backgroundColor: grey[300],
    transition: '300ms linear',
  },
  statName: {
    textTransform: 'uppercase',
  },
  viewer: {
    borderColor: 'transparent',
    borderWidth: 1,
    borderStyle: 'solid',
    [theme.breakpoints.up('md')]: {
      borderLeftColor: grey[300],
    },
    [theme.breakpoints.down('sm')]: {
      borderTopColor: grey[300],
    },
  },
});

const materials = [
  {
    name: strings.mixOne,
    density: 1.8, // kg/L
    printTime: 120, // seconds needed to print 1L of object
  },
  {
    name: strings.mixTwo,
    density: 2.1, // kg/L
    printTime: 150, // seconds needed to print 1L of object
  },
  {
    name: strings.mixThree,
    density: 1.5, // kg/L
    printTime: 1000, // seconds needed to print 1L of object
  }
];

class ModelViewer extends Component {
  state = {
    file: this.props.file,
    materialIndex: this.props.materialIndex,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.file.uid !== nextProps.file.uid) {
      return {
        file: nextProps.file,
        materialIndex: nextProps.materialIndex
      };
    }
    return null;
  }

  componentDidMount() {
    this.props.onMaterialChanged(materials[this.state.materialIndex]);
  }

  getStats = () => {
    const { classes } = this.props;
    const material = materials[this.state.materialIndex];
    const file = this.state.file;

    return (
      <Grid container direciton="row" spacing={2}>
        <Grid item xs={12}>
          <span className={classes.statName}>{strings.fileName}</span>:{' '}
          {file.name}
        </Grid>
        <Grid item xs={12}>
          <span className={classes.statName}>{strings.volume}</span>:{' '}
          {Math.round((file.volume / 1000000) * 100) / 100} L
        </Grid>
        <Grid item xs={12}>
          <span className={classes.statName}>{strings.mass}</span>:{' '}
          {Math.round((file.volume / 1000000) * material.density * 100) / 100}{' '}
          kg
        </Grid>
        <Grid item xs={12}>
          <span className={classes.statName}>{strings.printTime}</span>:{' '}
          {Math.round(
            (((file.volume / 1000000) * material.printTime) / 60) * 100
          ) / 100}{' '}
          h
        </Grid>
        <Grid item xs={12}>
          <span className={classes.statName}>{strings.dimensions}</span>:{' '}
          {Math.round((file.height / 10) * 100) / 100} cm x{' '}
          {Math.round((file.width / 10) * 100) / 100} cm x{' '}
          {Math.round((file.depth / 10) * 100) / 100} cm
        </Grid>
      </Grid>
    );
  };

  getMaterialButton = (i, material) => {
    const { classes } = this.props;
    return (
      <Grid item xs={12} sm={6} md={4} lg={4} key={i.toString()}>
        <Button
          variant={this.state.materialIndex === i ? 'contained' : 'outlined'}
          disableElevation
          color={this.state.materialIndex === i ? 'primary' : 'default'}
          className={classes.w100}
          onClick={() =>
            this.setState({ materialIndex: i }, () =>
              this.props.onMaterialChanged(materials[i])
            )
          }
        >
          <Grid container direction="row" justify="center">
            {material.name}
          </Grid>
        </Button>
      </Grid>
    );
  };

  getMaterialPicker = () => {
    return (
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        spacing={2}
      >
        {materials.map((item, index) => this.getMaterialButton(index, item))}
      </Grid>
    );
  };

  getViewer = () => {
    const { classes } = this.props;
    return (
      <Grid container direction="row">
        <Grid item xs={12}>
          <Box py={3}>{this.getMaterialPicker()}</Box>
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" className={classes.border}>
            <Grid item xs={12} md={5} lg={4}>
              <Box p={2}>{this.getStats()}</Box>
            </Grid>
            <Grid item xs={12} md={7} lg={8} className={classes.viewer}>
              <StlViewer url={`${apiUrl}/files/${this.state.file.uid}`} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    return this.getViewer();
  }
}

ModelViewer.propTypes = {
  file: PropTypes.object.isRequired,
  onMaterialChanged: PropTypes.func.isRequired,
};

export default withErrorHandler(withStyles(styles)(ModelViewer));
