import axios from 'axios';

const debug = false;

// API URL
const productionApiUrl = 'https://api.wool2loop.404.si/api/v1'; // TODO: api url
// const debugApiUrl = 'http://mama.recompile.it:8080/api/v1'; // TODO: debug api url
const debugApiUrl = 'https://wool2loop.recompile.it/api/v1'; // TODO: debug api url

// file upload
const stlFileUploadLimit = 200 * 1024 * 1024; // 200 MB

const apiUrl = debug ? debugApiUrl : productionApiUrl;

const captchaV2SiteKey = '6LfC09IZAAAAACB5qNZEvg-LsiCKC3Py0qx3WxFa';

// set axios api url
axios.defaults.baseURL = apiUrl;

export {
  debug,
  apiUrl,
  stlFileUploadLimit,
  captchaV2SiteKey,
};
