import { createMuiTheme } from '@material-ui/core/styles';

const primary = '#80cbc4';
const primaryLight = '#d0e8e6';

const Theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: primary,
    },
    secondary: {
      main: primary,
    },
  },
  typography: {
    fontFamily: 'Helvetica Neue',
    fontWeight: 'light',
  },
  shape: {
    borderRadius: 0,
  },
});

export {
  Theme,
  primary,
  primaryLight,
};