import { withStyles, LinearProgress } from '@material-ui/core';
import React, {Fragment} from 'react';
import { defaultStyles } from '../util/styles';
import { withRouter } from 'react-router-dom';
import { grey } from '@material-ui/core/colors';

const styles = theme => ({
  ...defaultStyles(theme),
  loading: {
    color: grey[500],
  },
  progress: {
    position: 'absolute',
    backgroundColor: 'tansparent',
    left: 0,
    right: 0,
    top: 0,
  },
});

function Loading(props) {
  const {classes} = props;
  return (
    <Fragment>
      <LinearProgress className={classes.progress}/>
    </Fragment>
  );
}

export default withRouter(withStyles(styles)(Loading));