import {makeStyles} from '@material-ui/core';
import {primary} from './theme';
import { grey } from '@material-ui/core/colors';

const defaultStyles = theme => ({
  textWhite: {
    color: 'white',
  },
  textCenter: {
    textAlign: 'center',
    width: '100%',
  },
  w100: {
    width: '100%',
  },
  titleMain: {
    fontWeight: 'bold'
  },
  h100: {
    height: '100%',
  },
  border: {
    borderColor: grey[300],
    borderStyle: 'solid',
    borderWidth: 1,
  },
  floatLeft: {
    float: 'left',
  },
  floatRight: {
    float: 'right',
  },
  fontWeightLight: {
    fontWeight: 200,
  },
  overflowYScroll: {
    overflowY: 'scroll',
  },
  overflowXScroll: {
    overflowX: 'auto',
  },
  flexGrow: {
    flexGrow: 1,
  },
  hideScrollBar: {
    '&::-webkit-scrollbar': {
      width: 0,
      background: 'transparent',
    },
  },
  screenHeight: {
    minHeight: '100vh',
  },
  drawerListItem: {
    width: 180,
  },
  link: {
    color: primary,
    textDecoration: 'none',
  },
  iconInButton: {
    marginRight: 12,
  },
  backgroundTransparent: {
    backgroundColor: 'transparent',
  },
  bold: {
    fontWeight: 600,
  },
  p0: {
    padding: 0,
  },
  select: {
    minWidth: 128.0,
  },
  alignRight: {
    textAlign: 'right',
  },
  blackLink: {
    color: 'black',
    fontWeight: 'bold',
  },
  backgroundDark: {
    backgroundColor: '#202020;'
  },
  rating: {
    marginTop: 4,
  },
  minScreenHeight: {
    minHeight: '100vh',
  }
});

// hook for use in functions
const useStyles = makeStyles(defaultStyles);

export {useStyles, defaultStyles};